import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO, PreviousNextNav } from "..";
export const _frontmatter = {
  "title": "Dig In"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Dig In To Web Accessibility" description="A listing of further resources and courses to deepen knowledge of web accessibility." mdxType="SEO" />
    <h1 {...{
      "id": "dig-in-to-web-accessibility"
    }}>{`Dig In To Web Accessibility`}</h1>
    <p>{`Keep digging deeper! Here are an assortment of resources, articles and courses -- free, paid, and with subscription.`}</p>
    <p>{`So when are you an accessibility professional? My answer is... `}<em parentName="p">{`shrug`}</em>{`? Keep caring, keep learning, keep championing change in your teams and codebases. To read more on this topic, check out `}<a parentName="p" {...{
        "href": "https://www.24a11y.com/2018/accessibility-pro-certified/"
      }}>{`Accessibility Pro Certified: To Be or Not To Be`}</a>{`.`}</p>
    <h2 {...{
      "id": "courses"
    }}>{`Courses`}</h2>
    <h3 {...{
      "id": "free"
    }}>{`Free`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.udacity.com/course/web-accessibility--ud891"
        }}>{`Web Accessibility`}</a>{`: A free Udacity course created by Google.`}</li>
    </ul>
    <h3 {...{
      "id": "with-subscription"
    }}>{`With Subscription`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontendmasters.com/courses/javascript-accessibility/"
        }}>{`Accessibility in JavaScript Applications`}</a>{`: A Frontend Masters course created by `}<a parentName="li" {...{
          "href": "https://twitter.com/marcysutton"
        }}>{`Marcy Sutton`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://egghead.io/courses/start-building-accessible-web-applications-today"
        }}>{`Start Building Accessible Web Applications Today`}</a>{`: An Egghead course created by `}<a parentName="li" {...{
          "href": "https://twitter.com/marcysutton"
        }}>{`Marcy Sutton`}</a>{` introducing accessibility through hands-on, technical examples.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.skillshare.com/classes/Hacking-Digital-Styleguides-for-Accessibility-Type-Color-Imagery/1920202818"
        }}>{`Hacking Digital Styleguides for Accessibility: Type, Color, Imagery`}</a>{`: A Skillshare course created by `}<a parentName="li" {...{
          "href": "https://twitter.com/tatianatmac"
        }}>{`Tatiana Mac`}</a>{`, focusing on the basics of accessibility with a design lens.`}</li>
    </ul>
    <h3 {...{
      "id": "paid"
    }}>{`Paid`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dequeuniversity.com/curriculum/online-classes/"
        }}>{`Deque University`}</a>{`: `}<a parentName="li" {...{
          "href": "https://www.deque.com/"
        }}>{`Deque`}</a>{` is a digital accessibility company offering curriculum through their Deque Unniversity platform. Maybe useful in particular if you're specifically interested in becoming `}<a parentName="li" {...{
          "href": "https://www.accessibilityassociation.org/certification"
        }}>{`certified via the International Association of Accessibility Professionals`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "intro-guides"
    }}>{`Intro Guides`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/intro/"
        }}>{`WebAIM Introduction to Web Accessibility`}</a>{`: A web accessibility introduction provided by WebAIM.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/Accessibility"
        }}>{`Mozilla Accessibility Guide`}</a>{`: A learning module on accessbility comprised of multiple guides, provided by Mozilla.`}</li>
    </ul>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11yproject.com/"
        }}>{`The A11y Project`}</a>{` is an open source accessibility resource site.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://a11yweekly.com/"
        }}>{`A11y Weekly`}</a>{` is a weekly newsletter with web accessibility content and news, curated by `}<a parentName="li" {...{
          "href": "https://twitter.com/DavidAKennedy"
        }}>{`David Kennedy`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLNYkxOF6rcICWx0C9LVWWVqvHlYJyqw7g"
        }}>{`A11ycasts`}</a>{` is an a11y YouTube series by `}<a parentName="li" {...{
          "href": "https://twitter.com/rob_dodson"
        }}>{`Rob Dodson`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "articles"
    }}>{`Articles`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.24a11y.com/"
        }}>{`24 Accessibility`}</a>{` is a series of articles related to web accessibility. New content is published every day for the first 24 days of December each year.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alistapart.com/blog/topic/accessibility/"
        }}>{`A List Apart (Topic: Accessibility)`}</a>{`. A List Apart is a web zine about web topics. These are all the articles published on A List Apart tagged with `}<inlineCode parentName="li">{`accessibility`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "communities"
    }}>{`Communities`}</h2>
    <ul>
      <li parentName="ul">{`The Web A11y Slack is a Slack team devoted to web accessibility topical discussion. Ask questions, share resources, get advice, etc. It used to have a landing page to request an invite, but that doesn't appear to exist anymore. @ me on Mastodon at `}<a parentName="li" {...{
          "href": "https://front-end.social/@amberley"
        }}>{`amberley`}</a>{` and I'll invite you!`}</li>
    </ul>
    <h2 {...{
      "id": "tools"
    }}>{`Tools`}</h2>
    <p>{`The below includes things I reach for a lot. The A11y Project also has a `}<a parentName="p" {...{
        "href": "https://www.a11yproject.com/resources/"
      }}>{`comprehensive listing of resources`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.google.com/web/tools/lighthouse/"
        }}>{`Lighthouse`}</a>{`: An open-source automated tool for measuring web pages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE`}</a>{`: An accessibility testing tool made by WebAIM.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.deque.com/axe/"
        }}>{`Axe`}</a>{`: An accessibility testing engine for websites and other HTML-based user interfaces, made by Deque.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/"
        }}>{`Accessibility Insights`}</a>{`: A comprehensive, free set of testing tools from Microsoft, using Axe under the hood.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pa11y.org/"
        }}>{`Pa11y`}</a>{`: A range of free and open source tools to help designers and developers make their sites more accessible (including a command-line interface, web dashboard, and CI integration tool).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/avanslaars/cypress-axe"
        }}>{`cypress-axe`}</a>{`: A package providing commands for Cypress to run a11y checks with axe-core.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://egghead.io/lessons/chrome-devtools-choose-accessible-colors-for-text-using-chrome-devtools-color-picker-contrast-ratio-score"
        }}>{`Chrome DevTools color picker contrast ratio score`}</a>{`: Check out this Egghead lesson to learn how to use the built-in color contrast ratio-aware color picker in Chrome DevTools.`}</li>
    </ul>
    <PreviousNextNav previous={{
      link: "/start-testing/",
      title: "Start Testing"
    }} next={false} mdxType="PreviousNextNav" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      