// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-a-11-y-friday-mdx": () => import("./../src/pages/a11y-friday.mdx" /* webpackChunkName: "component---src-pages-a-11-y-friday-mdx" */),
  "component---src-pages-a-11-y-testing-making-coffee-index-mdx": () => import("./../src/pages/a11y-testing-making-coffee/index.mdx" /* webpackChunkName: "component---src-pages-a-11-y-testing-making-coffee-index-mdx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-credits-mdx": () => import("./../src/pages/credits.mdx" /* webpackChunkName: "component---src-pages-credits-mdx" */),
  "component---src-pages-dig-in-mdx": () => import("./../src/pages/dig-in.mdx" /* webpackChunkName: "component---src-pages-dig-in-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-intro-mdx": () => import("./../src/pages/intro.mdx" /* webpackChunkName: "component---src-pages-intro-mdx" */),
  "component---src-pages-quick-wins-mdx": () => import("./../src/pages/quick-wins.mdx" /* webpackChunkName: "component---src-pages-quick-wins-mdx" */),
  "component---src-pages-start-testing-index-mdx": () => import("./../src/pages/start-testing/index.mdx" /* webpackChunkName: "component---src-pages-start-testing-index-mdx" */)
}

