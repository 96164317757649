import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO, Callout } from "..";
export const _frontmatter = {
  "title": "A11y Friday"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="A11y Friday" description="Do deploy on Friday and make it an a11y fix." mdxType="SEO" />
    <h1 {...{
      "id": "a11y-friday"
    }}>{`A11y Friday`}</h1>
    <Callout mdxType="Callout">
      <p>{`Unfamiliar with the "a11y" numeronym? Check out "`}<a parentName="p" {...{
          "href": "/intro/#what-is-a11y"
        }}>{`What is a11y?`}</a>{`".`}</p>
    </Callout>
    <p>{`Happy (hopefully chill) Friday! End of the week and you don't want to dig into a new big ticket, but want to make an impact? DO deploy, and make it an accessibility ("a11y") fix.`}</p>
    <h2 {...{
      "id": "a11ydeployfriday"
    }}>{`#a11yDeployFriday`}</h2>
    <p>{`Maintaining accessibility in your websites and applications takes consistent attention -- just like monitoring performance. It's not a one-and-done.`}</p>
    <h3 {...{
      "id": "1-pick-a-site"
    }}>{`1. Pick a site`}</h3>
    <p>{`So many options:`}</p>
    <ol>
      <li parentName="ol">{`A personal site or side project`}</li>
      <li parentName="ol">{`An open source site you can contribute to`}</li>
      <li parentName="ol">{`A site you contribute to at your day job`}</li>
    </ol>
    <h3 {...{
      "id": "2-run-an-audit"
    }}>{`2. Run an audit`}</h3>
    <p>{`Run an `}<a parentName="p" {...{
        "href": "/start-testing"
      }}>{`accessibility audit`}</a>{`.`}</p>
    <h3 {...{
      "id": "3-do-something"
    }}>{`3. Do something`}</h3>
    <p>{`All sizes are impactful.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Teeny`}</strong>{`: Fix one issue.`}</li>
      <li parentName="ol"><strong parentName="li">{`Small`}</strong>{`: Fix multiple issues!`}</li>
      <li parentName="ol"><strong parentName="li">{`Medium`}</strong>{`: Create informative issues or tickets capturing work that needs doing based on your audit findings.`}</li>
      <li parentName="ol"><strong parentName="li">{`Large`}</strong>{`: After creating issues for yourself and others, start working through them!`}</li>
    </ol>
    <p>{`If you make one small change, you've made the internet a better place today.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      