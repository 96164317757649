import { Flex } from "theme-ui";
import { ColorContrast, ImageGallery, SEO, Callout, PreviousNextNav } from "../../../../src";
import audio from "../../../../src/assets/a11y-coffee-quick-wins.m4a";
import * as React from 'react';
export default {
  Flex,
  ColorContrast,
  ImageGallery,
  SEO,
  Callout,
  PreviousNextNav,
  audio,
  React
};