import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO, Callout } from "../../";
export const _frontmatter = {
  "title": "A11y Testing Is Like Making Coffee"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="A11y Testing Is Like Making Coffee" description="There are many different ways to make coffee, with different results. There are also different ways to test a11y, with different results!" mdxType="SEO" />
    <h1 {...{
      "id": "a11y-testing-is-like-making-coffee"
    }}>{`A11y Testing Is Like Making Coffee`}</h1>
    <Callout mdxType="Callout">
      <p>{`This piece was written by a friend and colleague, `}<a parentName="p" {...{
          "href": "https://twitter.com/madalynrose/"
        }}>{`Madalyn Parker`}</a>{`, and `}<a parentName="p" {...{
          "href": "https://madalyn.dev/blog/a11y-testing-coffee/"
        }}>{`originally posted on her blog`}</a>{`. Our ideas have percolated together (clearly!) and we thought they belonged together on this site!`}</p>
    </Callout>
    <h2 {...{
      "id": "some-background"
    }}>{`Some Background`}</h2>
    <p>{`I had the privilege of creating course material to teach accessibility testing for work this past quarter.`}</p>
    <p>{`I agonized over what to include, how to deliver it, how to make it approachable. I decided that I wanted to center everything around `}<strong parentName="p">{`tools`}</strong>{` and `}<strong parentName="p">{`processes`}</strong>{`, teaching about what sorts of violations each are intended for, what they catch, and what they miss.`}</p>
    <p>{`I envisioned handing my trainees funnels that were narrower and narrower, allowing increasingly smaller violations pass through.`}</p>
    <p>{`Tooling overwhelm is real when it comes to anything, but I find that this is particularly true when those tools determine whether something is safe or legal. I aim to minimize hesitation to choose a tool and do the testing at all for fear of choosing the wrong one. And for that, I sat with the concepts and played with metaphors until I found something that fit.`}</p>
    <p>{`And I arrived at:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.96339677891655%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABRNAAAUTQGUyo0vAAADCUlEQVQ4y21US28SURjl35i4NK6buPSXuDGxdlFTN201aYtWuylobePGtjsaQm1k0RaDwdDogkd5yWuAAQYGZmBmeD+O33ehaWr8kpt7A5dzz3fO+bBVq1VwFQoFjMdjDIdD3FQylUK9XkepVEKhWES328VkMkG73cb/ajqdwibLMprNJra33+Ho6BgnJyfY39+Hw+GE3f4GG5ub2N114P3OjlgMXqRlGIYANow26moD3Y7OkLAxWKVSQb/fRzAYhNvtRjqTgdfrxfn5BVLEMp/PI51O00MHaLVakCRJfBaNXtOeg1LX6JyGUZBhY6plAmw0mhgMBnA6P+Dw8BBbdjv29vbEvrz8ApqmibZYEpaJCXB3crmMwXiAaspAMzYH5DKJfpm+ZHDTNAULZh4KheA5PRV3WD8uBuK717E4EuEoFCmHXEGCafVh06wOfklF+LISJLmMRDwudGIwbpe1YiBu+cYMbrsizJwiUM4gmkuiY/ZmGg7I2bphQqaWiyV5phmxY9f/EIjL5cLXszPE6aHziwv0ej0BWiwU0bYM+CgFea1z6/L8hK5lkY4NJJJJJGlJBBiLxbC2to5ni4t4vrSEjY1N0vcIKysvhYE1RUW3Zd6Jjo1ReXEGOXNqXUWbWrJIR6EVtc4tsykqxYM1/Uhm+Xzf0R/0oTYbpHeBoqcJo2z/htPqdFBWm9CtLrp0QVVVlAiYL4dCYbgop2ckwfr6K3g8Huj0OJPhezEySTDUdV1MAUeiTSaUqgpUTcdgPjV90m00GqFJkrA5HC2H04lA4Kcwj8GqiiLSIRhySDkGSq0mzvlc7g7r0eR2nx+hKDWx5+huNpuFps9yKgBZH4VeqBFgiZzO0KRwNLgysgRvwA/v5SVimSzqOhkXT9C4qWKiGJB/Gw5HxFkAcssMxiA8LaPhiBI1Qd8aYvWxE0/ureLp/dfYevQF3mMfrn4HxWRxi1yRSISm6hP8/h8zwA4Zwbm7/duYbUatg4MFPz4/vMLxQhhvH7gR/TaToyxXhK6cACbCXnD9Bb6AOdJf5dnlAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Accessibility is Like Making Coffee ",
          "title": "Accessibility is Like Making Coffee ",
          "src": "/static/73f5895207ccdbcdd721ada8b35d788e/b4b06/title.png",
          "srcSet": ["/static/73f5895207ccdbcdd721ada8b35d788e/376d0/title.png 345w", "/static/73f5895207ccdbcdd721ada8b35d788e/1dda8/title.png 690w", "/static/73f5895207ccdbcdd721ada8b35d788e/b4b06/title.png 1380w", "/static/73f5895207ccdbcdd721ada8b35d788e/09b9a/title.png 2070w", "/static/73f5895207ccdbcdd721ada8b35d788e/4e971/title.png 2732w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I could have used any metaphor that involves scaling quality of experience. I could talk about audio or image formats, but I chose coffee because it's tangible, because I love coffee, and because I think most people have a frame of reference for it.`}</p>
    <p>{`In accessibility testing, and when making coffee, we are shooting for the `}<em parentName="p">{`smoothest`}</em>{` experience. We want to get to the `}<strong parentName="p">{`essence`}</strong>{` of the thing we're making. We want to filter out the grit and bitterness and include everything that makes our final product enjoyable.`}</p>
    <h2 {...{
      "id": "coffee-methods"
    }}>{`Coffee Methods`}</h2>
    <p>{`So let's talk about coffee! There are `}<em parentName="p">{`a lot`}</em>{` of ways to make coffee and people have `}<em parentName="p">{`a lot`}</em>{` of opinions about which way is The Best Way. The thing is, the best way is whatever makes the experience best for whoever is drinking the coffee.`}</p>
    <p>{`I make my coffee one way, my husband makes it his way. And we both order entirely differently if we go out and have someone else make us coffee.`}</p>
    <p>{`There are many different types of coffee-making implements and each spits out slightly different coffee.`}</p>
    <p>{`Some things that can affect the quality of coffee are:`}</p>
    <ul>
      <li parentName="ul">{`how finely the coffee beans are ground (more oils make it into the final product with a finer grind, meaning you get nuanced flavor)`}</li>
      <li parentName="ul">{`filtration system (paper filters catch and spread those oils)`}</li>
      <li parentName="ul">{`time spent on the process`}</li>
    </ul>
    <p>{`I've split accessibility testing tools into four categories, so I'll talk about four ways to make coffee.`}</p>
    <h3 {...{
      "id": "french-press--automated-testing"
    }}>{`French Press === Automated Testing`}</h3>
    <p>{`French press coffee is nice because:`}</p>
    <ul>
      <li parentName="ul">{`it's fairly hands-off (set a timer, walk away, come back!)`}</li>
      <li parentName="ul">{`uses one pot and no extra filter, making cleanup a breeze!`}</li>
      <li parentName="ul">{`uses a course grind (quick to grind, no fancy tools necessary!)`}</li>
    </ul>
    <p>{`However it also has some draw backs:`}</p>
    <ul>
      <li parentName="ul">{`can get gritty or sludgey`}</li>
      <li parentName="ul">{`unfiltered coffee tends to be on the bitter side and misses nuanced flavors`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.96339677891655%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABRNAAAUTQGUyo0vAAACPElEQVQ4y31UO2/TUBjNT2BgAIQqFAlQVSGKRAEhxICQkGAFtSqsMCAhsbAxIPED2Nhg6l9ISwWEpR2at1ORh+0EO47jvGqbNEocO87h3lscnDjmDva918fH53zfuY6AjMlkgkWD7nvPwjB+LB0Rb6IoCtrtNmzbQafTwWg0CoC73S4qlQrDDQYD1Ot1tFotNvdwU8JaTUE+f4hSqYxcjoMkyxTBiF3XZRhVVbG3v49sNotMJsPuPM9jOBz+I1xkxyKAQqGAJvm6LNcwHo+nGNM0mUpVbcD1vRewbBElX3Z3EdvewZGuY0JUtTWVkJ/YoSqpEsuyCGEVhmFM9/11Zgqp1csrV3Bt7QZu3bmLc0sXsB2LQW83mDrbtlGtVpFOZ2CTD//M59i63+/PNG9KSOuwvrGBl69e49nTTaw/eYxk4gBi+RCdVgMDoqxYLJKa5RhB77eJZrNJmtciJM6sZXqRKzw+v3uBrQ9v8WbzHt4/f4i9HzsQ6iJksQxDP2Kd53kBI+sYmtaAUquHKxRKBTxaPYP7K6fx4HoUN6OnsPXpIySxCC59ANPQicVfSKVSsB0HkiRBkVW442BGGaGmabi9topLS2exHD2Pq8sX8f3bVwag8aF2RVFkHQ4LdCCHZq8HXhBZB3vHJ1YcooZGhOM4JBIJktUa67Tfop80NId/n7JICIKAeDyOZDJJupxmp2O+boEaegtK4M8VndPj5h0xmsFFNv0jElaT//0wFv04ZhSGkS2yNL8/v/4DS0hYuVzFKbgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The French Press is **Automated Accessibility Testing**",
          "title": "The French Press is **Automated Accessibility Testing**",
          "src": "/static/b917d6a311a6de42b6f64848f4d9e5d3/b4b06/french-press.png",
          "srcSet": ["/static/b917d6a311a6de42b6f64848f4d9e5d3/376d0/french-press.png 345w", "/static/b917d6a311a6de42b6f64848f4d9e5d3/1dda8/french-press.png 690w", "/static/b917d6a311a6de42b6f64848f4d9e5d3/b4b06/french-press.png 1380w", "/static/b917d6a311a6de42b6f64848f4d9e5d3/09b9a/french-press.png 2070w", "/static/b917d6a311a6de42b6f64848f4d9e5d3/4e971/french-press.png 2732w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Like the French Press, automated testing lets grit through. Automated testing can only reliably identify a small subset of accessibility violations (I've heard numbers between 10% and 30%). For some people, the violations caught by automated testing will give them a good experience on your site. For others, it won't find even half of the violations that render it inaccessible. Automated testing is also relatively quick and hands off, but it only gains you so much.`}</p>
    <p>{`Some wonderful automated tools include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.deque.com/axe/"
        }}>{`axe`}</a>{` - this is what sits under `}<em parentName="li">{`most`}</em>{` automated tooling. You can leverage it directly in your code using the `}<a parentName="li" {...{
          "href": "https://github.com/dequelabs/axe-core"
        }}>{`axe-core`}</a>{` API or use their browser extensions to run on rendered pages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.google.com/web/tools/lighthouse"
        }}>{`Lighthouse`}</a>{` includes accessibility auditing and runs axe. It's shipped right in Chrome DevTools and also has a `}<a parentName="li" {...{
          "href": "https://github.com/GoogleChrome/lighthouse-ci/blob/master/docs/getting-started.md"
        }}>{`CI integration`}</a>{` you can add to your pipeline.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/"
        }}>{`Accessibility Insights`}</a>{` is my all time favorite accessibility testing tool. It's built by Microsoft and has versions for Android, web, and Windows. I'll be talking about their Chrome extension, which also uses axe, but all of these are top notch amazing for auditing. There are a lot of moving pieces to this tool, and its "Fastpass" feature fits the "French Press" bill.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE Web Accessibility Evaluation Tool`}</a>{` is created by `}<a parentName="li" {...{
          "href": "https://webaim.org/"
        }}>{`WebAIM`}</a>{`, which is an incredible organization responsible for a lot of `}<a parentName="li" {...{
          "href": "https://webaim.org/projects/"
        }}>{`accessibility usage and demographic data`}</a>{`, `}<a parentName="li" {...{
          "href": "https://webaim.org/standards/wcag/checklist"
        }}>{`approachable documentation`}</a>{`, and `}<a parentName="li" {...{
          "href": "https://webaim.org/services/"
        }}>{`a variety of services for education and evaluation`}</a>{`. They have their own API and this tool can either be run from their website or as a browser extension.`}</li>
    </ul>
    <h3 {...{
      "id": "aeropress--semi-automated-testing"
    }}>{`Aeropress === Semi-automated Testing`}</h3>
    <p>{`One step up from the French Press is the Aeropress.`}</p>
    <p>{`Aeropress coffee is nice because:`}</p>
    <ul>
      <li parentName="ul">{`it's super quick (the longest step is waiting for water to boil!)`}</li>
      <li parentName="ul">{`it uses a filter (get some of those good coffee bean oils without much effort)`}</li>
      <li parentName="ul">{`uses a finer grind (again with the oils!)`}</li>
      <li parentName="ul">{`customizable (can make "espresso" or cold brew with it)`}</li>
    </ul>
    <p>{`But it also has some draw backs:`}</p>
    <ul>
      <li parentName="ul">{`can still get sludgey`}</li>
      <li parentName="ul">{`only makes one cup at a time`}</li>
      <li parentName="ul">{`takes physical strength`}</li>
      <li parentName="ul">{`finicky`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.96339677891655%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABRNAAAUTQGUyo0vAAACgUlEQVQ4y3VUS2sTYRSdHyAo6F8Q6cKVgmJFt+rCX6AgulTqXjci3bhQkOKqIrhQ0dQiCkEwscFg82yeNpmQpM2reSeTZPKaJJPjvV+Mppn4QUhy537nnnvOvSONx2PwmX4PBgP873DONG9RnD/SNKDrungYiUQQjUaRzWYhyzKSySTS6TTq9bp43u/3RU4qlUYsFjMQkGYrDgYa6kod5XIZXq8XmUwGiqKgWCyi3W6Ly5qmiQI+n08U7Ha7qNVqKBQKaLVa/wCtFjtWH6/hxdprYqMcqsqXuEhLVRGPx2GxWKjgDoLBIBwOBzweD5xOp2AvjUYjcend2w1cOH8Ft2/dhzIHOHs6nQ7cBBAKhYQ0fH+2S2k4nACaPnzCpeVrWLn3kKirItbrdjAkGWYPa53P55EjjZn1vDEEOBSBjY+fcfLUGdy4eRcDbQJSOMggtZcQ2rEprCtruCvH4PQHFrus6xOGz1af4LgkYXlpCYFoQsRUMqhWKoq2WPDtbQd6vR5kct5qsyFAoP2+dpihGAVq+/rTdZy9s4Jzj57j6vuvyLfaiORkRPaiUBsNVKpVhMNhMqiHoC8E344fbrcLhXzBCKhR26dfmnD0zTccsfzC5S8/kCOAyK4LP+1W5NL7UGls2MkD0s/vc6NSyqJUTJEcPSPgiIS++MqEY+ubOLFpxwNHiDJ0tJoKKuWSaLfZbIp2x8KYkWFTDIOdqjdg2c8hWKxwiojx3AVo1mQyIZFICNAqMec5nTXCwHB+P/U//3kDnC4XzGYzvm9twUW/eUOqFJ9318CQQbh1fSaBR4pXjgfYRq4yU1VVF74o/jKcpzzNMbCmYjw+082Yf0tNY78BBoFWOQ+vDz0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The Aeropress is **Semi-automated Accessibility Testing**",
          "title": "The Aeropress is **Semi-automated Accessibility Testing**",
          "src": "/static/7780314120c2cda7342ec0d2d4f5a343/b4b06/aeropress.png",
          "srcSet": ["/static/7780314120c2cda7342ec0d2d4f5a343/376d0/aeropress.png 345w", "/static/7780314120c2cda7342ec0d2d4f5a343/1dda8/aeropress.png 690w", "/static/7780314120c2cda7342ec0d2d4f5a343/b4b06/aeropress.png 1380w", "/static/7780314120c2cda7342ec0d2d4f5a343/09b9a/aeropress.png 2070w", "/static/7780314120c2cda7342ec0d2d4f5a343/4e971/aeropress.png 2732w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Like the Aeropress improves upon French Press coffee by giving you a smoother brew with more nuance, semi-automated testing will catch more accessibility violations. But also like the Aeropress, it'll take some extra work from you.
These tools flag `}<em parentName="p">{`possible`}</em>{` violations and highlight features that `}<em parentName="p">{`potentially`}</em>{` create issues. They require you to use your judgement to decide what is a bug and what is indeed a feature. I'll also include integration tests in this category, as you have to simulate user interaction in order to catch violations.`}</p>
    <p>{`These tools include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE Tool`}</a>{` (again!) because:`}<ul parentName="li">
          <li parentName="ul">{`its "Alerts" section flags `}<em parentName="li">{`potential`}</em>{` violations`}</li>
          <li parentName="ul">{`it surfaces all elements using `}<a parentName="li" {...{
              "href": "https://www.w3.org/WAI/standards-guidelines/aria/"
            }}>{`WAI-ARIA`}</a>{`, which needs to be used mindfully and intentionally and should always be looked at critically`}</li>
          <li parentName="ul">{`it shows page structure with Headings and Landmarks but leaves it up to you to determine whether they meet standards`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/"
        }}>{`Accessibility Insights`}</a>{` (also again!) because:`}<ul parentName="li">
          <li parentName="ul">{`its "Tab Stops" feature requires you to actually tab through the page manually to verify that tab order follows both reading order and DOM order`}</li>
          <li parentName="ul">{`its "Ad hoc tools" surface structure similarly to the WAVE tool`}</li>
        </ul></li>
      <li parentName="ul">{`Integration Tests can be used to run a11y checks, interact with the UI, and run them again, verify that focus is where it should be (e.g. is it moved to the modal when the modal is opened?)`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/pa11y/pa11y"
            }}>{`pa11y`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/avanslaars/cypress-axe"
            }}>{`cypress-axe`}</a></li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "pour-over--manual-testing"
    }}>{`Pour Over === Manual Testing`}</h3>
    <p>{`The Pour Over is the generally accepted Best Way to Make Smooth Coffee. There are a lot of variables to get right (weight, temperature, humidity, time, technique), but once you've perfected the art you're left with a bright cup of very smooth Joe. Want to use a finer grind? It's going to take more time. Pour Over coffee involves hovering over your Chemex or other implement and drizzle boiling water in a circular motion in order to lengthen the "blooming" process, allowing for trapped gases, that might make the coffee harsh, to escape.`}</p>
    <ul>
      <li parentName="ul">{`truly the smoothest brew`}</li>
      <li parentName="ul">{`bright flavors and highlighted fruity notes`}</li>
    </ul>
    <p>{`Trade Offs:`}</p>
    <ul>
      <li parentName="ul">{`isn't as bold as the coffee you would get from a French Press`}</li>
      <li parentName="ul">{`requires `}<strong parentName="li">{`time`}</strong>{` and `}<strong parentName="li">{`attention`}</strong></li>
      <li parentName="ul">{`easy to mess up`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.96339677891655%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABRNAAAUTQGUyo0vAAACv0lEQVQ4y31TS2sTURTOX3LrQnSjS7vQH2C7EkEqClIXYheVbkSwboqNUDBgAmK7aG1aTSVWMiGvSZMmaWKTziPv10we5DWTz3tPO60J6oGBc+ee+53vnO8cG5h5vV6EwxFYZoxGMAwDvV4PI+Zz477f70cgEMC0dbtdFAoF8m39wRDrHxxwuVzY3NhAMplEKnMCh8OBj04nfD4fBZbLZSwtvcSa3Y7dvT1sb28zEmF82dnB6uoqVFU9AywngzgRBZQaOrJyHjW9jXrCj7qcgd7uIM8COVtJllEqleDZ38c3jweCIMC9u0ugiUSCWBKgpmTgXZqF5HGhpyShbr5Byr5Al2P2KYqCTqeDfr9P/zgzURSpTW63e6L08XgMG3cCn9awPncNzvnbcD64CTV+1qdqUUVL1y4etFotxGIxuFmZBwcHyOVylIwD8Y8YUsP7A9hfzOPpzFVsvl+hiz4TQQyHqGTTMHGSzSLEyuPmj4YhxmOo1WowTXOSoWmcqXgkfMXy3A0UT4/pzNWNRYOQ1RyajGWKicVL5f2MxeMIBNmdJKNSrkCR8hgwUsTQNA1yjn1bcCzcxWlcoPNg2IcQ+gmPsI90Lo1qpYpUKoXhcICjwwSkUxkFtYhfx1l0tQosHCq53mjAvbWB8N5n1Mp5tNpchC4Og9+REn+gpTWg5gusfyLaegmKmmdv6qjX69C0Jp/cSVHevX2NuTu38PzhPcxcv4KVV8sUMBz0WInDi4b/y8YEduYRYCQUwOLCYyw+e4JH92eRTMQpUNM0yLKCdDqNTCZD28KNC2Epe6nwmEBtf2YfGcZlVvaoUqkgGo3SqPABzjKlrdIsEOs8UbKVkfaYgRrnwLxHfAu4GM1mkxhbYzLdhok5nKZvXfI5i0REApUkCcVicYoh/s5wOpMVwLegWq1C13UC75zv6/8AfwP8UE4FNwYyRQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pour Over is **Manual Accessibility Testing**",
          "title": "Pour Over is **Manual Accessibility Testing**",
          "src": "/static/c4a0bba89f373d9e7c88a8fce4a7051c/b4b06/pour-over.png",
          "srcSet": ["/static/c4a0bba89f373d9e7c88a8fce4a7051c/376d0/pour-over.png 345w", "/static/c4a0bba89f373d9e7c88a8fce4a7051c/1dda8/pour-over.png 690w", "/static/c4a0bba89f373d9e7c88a8fce4a7051c/b4b06/pour-over.png 1380w", "/static/c4a0bba89f373d9e7c88a8fce4a7051c/09b9a/pour-over.png 2070w", "/static/c4a0bba89f373d9e7c88a8fce4a7051c/4e971/pour-over.png 2732w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`With manual testing, you have to roll up your sleeves and spend time with your product. You have to be present and interact with it. But you'll catch things that nothing else will. I cannot stress this enough: `}<strong parentName="p">{`you cannot determine if your site is accessible without manual testing`}</strong>{`.`}</p>
    <p>{`Manual testing means adopting workflows that disabled people use and determining if your site is usable. Practice makes approximate here; you'll never use a screen reader the way someone who relies on one does. And screen reader users are not a monolith anyhow. Data shows that most people who regularly use assistive technology customize it to suit their very specific needs.`}</p>
    <p>{`So these tools are:`}</p>
    <ul>
      <li parentName="ul">{`navigating with your keyboard`}</li>
      <li parentName="ul">{`using a screen reader`}</li>
      <li parentName="ul">{`turning your screen magnification way up (300%+)`}</li>
      <li parentName="ul">{`using a site with audio muted`}</li>
      <li parentName="ul">{`verifying that the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion"
        }}>{`prefers reduced motion`}</a>{` setting is respected`}</li>
    </ul>
    <p>{`I will also add `}<a parentName="p" {...{
        "href": "https://accessibilityinsights.io/"
      }}>{`Accessibility Insights`}</a>{` into this list because it will walk you, step-by-step, through an `}<a parentName="p" {...{
        "href": "https://accessibilityinsights.io/docs/en/web/getstarted/assessment"
      }}>{`accessibility audit`}</a>{`. It provides documentation and all the scaffolding you need to make sure that a site is compliant. And that is so powerful!`}</p>
    <p>{`However, these workflows are difficult to adopt and attempt if you don't understand the use cases behind them or what success criteria are. And that's why none of these methods or tools are as good as user testing.`}</p>
    <h3 {...{
      "id": "going-to-a-cafe--user-testing"
    }}>{`Going to a Cafe === User Testing`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1380px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.96339677891655%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABRNAAAUTQGUyo0vAAAD1ElEQVQ4y0WSa0ybVRzG3w8mJpqYGDXZB9R9WHTeEqZGEqO4xDlYzDAuwki8LGzLppixzTnjluGMzKEbjMtGgcK4tMBoaYFd2jIKhTFaSqFQCqWUSztKuYzrFgnB+OHned8N/fC878k5z3nO8///HyncN4+M6YEl7t5w4e8MMTf4AI9thKn+BSbd95W/fC7D1zGhcOT1rHdFOZPvb/wl+RPxLOG3h9CVNGK/5cZWb6ehwkS4f15cDtJq7CTgmCTUM4tecHTqBnqaB3Fbh7jXO6eI33PP/S84M7BMWDgxVbdQVaAn75yK+rJbjDsjLA6vYrlmw1TTolww17RSdLEMdXYFVn0HvUJ4zDnFgn9VcSzJVke7pxh1RQg4w/S3BzAIdz57kDGxN+KcVJxGPItKWbODK3jbAmiu1OJqGqA0R0NNkVGpymnxII11R0hIT+PV1M94+UA80UeTiE7bywcnU/jwpxSiDu4ko0jNw+E1Qo/LkoVl11WqOiFmoL7cxM9p6UqFkuxq27Fkntr3Pk8kv8NbP37BS6m72Hr8c57bvx1p14ucyMtm1b8uBGcVwUeDWlRcy45HRP9l4WnROsnfNUlc+iHeO53MK0d2s+VIAq8d28OO377j7ZNJSLu3cOpyPqsj6wR7Z5nxLiui8jAUCNfyAxstEQ6neCM1jhf2RyMlvs6TX8bwzL4YNh36mOcPCIefbOJ4TpYQ/Jspz4ISpxmRipXAGisjayyJYWxERpmy/ELWVS1nRZ9+Ly3jV1Uh59XFZJZc5bzALyoVZpOdJTFtd4uPzbFxnDiThU5jITuvGq1Ig+ww3Pc4hxGhvuxbJeSYZqI9SMhkY8E5xto4iqu10X+Y9z5gMbCO8UoJX2/fzP6Povgm5ln2bnuaw7FR9FrczA09FMJzSPd9f9GibyXx03h+yK1CX1pBR2ObiMwMwyI6w46QEvoJ9yLtlVW4MpKwnkmi6vudZCW+ydmErbQZ25kWGZTNSUERXk1tE39W6rnU5EDVYKW4oYVqqwttczflpk7KzQIWO2Xmu5Ret1JhakNj6aD4RhuF120UGJu5Y3YTFkOS1Lk6CmrMNHonMXT50Hf7afAEydGbOZWrxtg3Tq3YL73t4Oi5S+hdo9Q6/QpkrqF3FO2dPnJzqlHnG5Cy/9CSr7kpiH5qOr0K6lwj5NVZyNGZsPhnaBQP1LvHySyvQ9vuptYxxDXBkyGvK1t7uJClpSDPiJR7oZp87U3qegKPSHYvBleAy/XNxCd9xeHTGaSmZ7In5Vvejd1BvuE2hv+4g+i6hqm09XBRCBYKwX8B/A+WqBoBFpEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Cafe is **Accessibility User Testing**",
          "title": "Cafe is **Accessibility User Testing**",
          "src": "/static/b625b5c37609dacbee2bdf6a9c7baa63/b4b06/cafe.png",
          "srcSet": ["/static/b625b5c37609dacbee2bdf6a9c7baa63/376d0/cafe.png 345w", "/static/b625b5c37609dacbee2bdf6a9c7baa63/1dda8/cafe.png 690w", "/static/b625b5c37609dacbee2bdf6a9c7baa63/b4b06/cafe.png 1380w", "/static/b625b5c37609dacbee2bdf6a9c7baa63/09b9a/cafe.png 2070w", "/static/b625b5c37609dacbee2bdf6a9c7baa63/4e971/cafe.png 2732w"],
          "sizes": "(max-width: 1380px) 100vw, 1380px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`User testing means you get to understand and witness the barriers to access that disabled people encounter when they use your site. You'll be able to make note of their workflows so you can approximate them in future manual tests. We cannot talk about accessibility if we don't also talk about disability. You'll know you have an accessible site when you know that disabled people can use it and have a good experience.`}</p>
    <p>{`And this is going to a cafe. Can I make my Aeropress every morning and be happy with my coffee? Sure. But if I go to a cafe they have all the ways to make coffee and many I've never even heard of. They can make an Oat Milk Latte with Cayenne for my husband, a Pour Over for my friend, and a Cafe Au Lait for me. We don't have to make the coffee but we do have to leave the house. That extra effort is worth it when everyone gets exactly what they want.`}</p>
    <p>{`There are many accessibility testing services like `}<a parentName="p" {...{
        "href": "https://www.makeitfable.com/"
      }}>{`Fable`}</a>{` that will connect you with disabled people for on-demand user testing and research.`}</p>
    <h2 {...{
      "id": "closing"
    }}>{`Closing`}</h2>
    <p>{`I hope this metaphor helped break down how you might use different tools in accessibility testing. Some tools can cover a lot of ground.`}</p>
    <p>{`I have a blog post on `}<a parentName="p" {...{
        "href": "https://madalyn.dev/blog/manual-a11y-testing-setup/"
      }}>{`how to get set up for manual accessibility testing`}</a>{` and another on `}<a parentName="p" {...{
        "href": "https://madalyn.dev/blog/5-things-a11y-keyboard-testing/"
      }}>{`using a screen reader and the keyboard`}</a>{` that will hopefully make manual testing even more approachable.`}</p>
    <p>{`I think overwhelm keeps people from choosing their tools and trying them out. They'll either choose instant coffee as the easier option (no testing) or go straight to the cafe (hire someone else). I'd like to advocate for trying some new tools out and seeing what kind of experience you can create with them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      