import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO } from "..";
export const _frontmatter = {
  "title": "Credits"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Credits" description="Credits and thanks for resources that made this site possible." mdxType="SEO" />
    <h1 {...{
      "id": "credits"
    }}>{`Credits`}</h1>
    <h2 {...{
      "id": "colleagues-and-friends"
    }}>{`Colleagues and friends`}</h2>
    <p>{`Thank you to the colleagues and friends in my career who I have learned accessibility from and with: `}<a parentName="p" {...{
        "href": "https://twitter.com/davidakennedy"
      }}>{`Dave Kennedy`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/marcysutton"
      }}>{`Marcy Sutton`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/AaronGustafson"
      }}>{`Aaron Gustafson`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/madalynrose"
      }}>{`Madalyn Parker`}</a>{`.`}</p>
    <h2 {...{
      "id": "projects-and-resources"
    }}>{`Projects and resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/"
        }}>{`W3C Web Accessibility Initative`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://a11yproject.com/"
        }}>{`A11y Project`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/"
        }}>{`WebAIM`}</a></li>
    </ul>
    <h2 {...{
      "id": "theme-ui"
    }}>{`Theme-UI`}</h2>
    <p>{`The starting point for this site was taken directly from the `}<a parentName="p" {...{
        "href": "https://theme-ui.com/"
      }}>{`Theme UI site`}</a>{` codebase.`}</p>
    <p>{`Thank you to `}<a parentName="p" {...{
        "href": "https://twitter.com/jxnblk"
      }}>{`Brent Jackson`}</a>{` for that open source site, and for the creation of the Theme UI project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      