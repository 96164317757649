import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Flex } from "theme-ui";
import { ColorContrast, ImageGallery, SEO, Callout, PreviousNextNav } from "..";
import audio from "../assets/a11y-coffee-quick-wins.m4a";
export const _frontmatter = {
  "title": "Quick Wins"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Quick Wins for Web Accessibility" description="An introduction to the six most common accessibility errors, and how to fix them." mdxType="SEO" />
    <h1 {...{
      "id": "quick-wins-for-web-accessibility"
    }}>{`Quick wins for web accessibility`}</h1>
    <h2 {...{
      "id": "summary"
    }}>{`Summary`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://webaim.org/"
      }}>{`WebAIM project`}</a>{` publishes an `}<a parentName="p" {...{
        "href": "https://webaim.org/projects/million/"
      }}>{`accessibility evaluation of the home pages of the top 1,000,000 websites`}</a>{`, updated annually (twice so far). The evaluation uses an `}<a parentName="p" {...{
        "href": "https://wave.webaim.org/"
      }}>{`automated testing tool called WAVE`}</a>{`. The report from February 2020 found detectable WCAG 2 failures on 98.1% of tested home pages, increased from 97.8% found in February 2019.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Automated testing can only reliably detect a small portion of all possible WCAG conformance failures.`}<sup parentName="em" {...{
            "id": "fnref-1"
          }}><a parentName="sup" {...{
              "href": "#fn-1",
              "className": "footnote-ref"
            }}>{`1`}</a></sup>{` This means that the actual conformance level is extraordinarily likely to be even lower.`}</em></p>
    </blockquote>
    <p>{`The topic and practice of web accessibility is wide-ranging and nuanced, which can make it feel hard to get started. A good place to start is to correct the most common errors identified in the study. This page will overview those most common errors.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/quick-wins/#most-common-web-accessibility-errors"
        }}>{`Most common web accessibility errors`}</a><ol parentName="li">
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#1-low-contrast"
            }}>{`Low contrast`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#2-missing-alt-text-for-images"
            }}>{`Missing alt text for images`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#3-empty-links"
            }}>{`Empty links`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#4-missing-form-input-labels"
            }}>{`Missing form input labels`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#5-empty-buttons"
            }}>{`Empty Buttons`}</a></li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "/quick-wins/#6-missing-document-language"
            }}>{`Missing document language`}</a></li>
        </ol></li>
    </ul>
    <h2 {...{
      "id": "most-common-web-accessibility-errors"
    }}>{`Most common web accessibility errors`}</h2>
    <h3 {...{
      "id": "1-low-contrast"
    }}>{`#1: Low contrast`}</h3>
    <p>{`The WebAIM Million report found low contrast text on 86.3% of tested home pages in February 2020.`}</p>
    <p>{`Users must be able to `}<em parentName="p">{`perceive`}</em>{` content on the page. A sufficient contrast ratio supports that perception. Ensure that you're using sufficient color contrast.`}</p>
    <p>{`In WCAG, contrast is measured using "relative luminance". Relative luminance is the brightness between two colors.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` Relative luminance is expressed as a ratio ranging from 1:1 (white text on a white background) to 21:1 (black text on a white background).`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`The first of the following two examples shows black text on a white background. The second example shows white text on a white background, illustrating a contrast ratio of 1:1, which is completely imperceptible to anyone.`}</em></p>
    </blockquote>
    <ImageGallery mdxType="ImageGallery">
      <ColorContrast background="#fff" foreground="#000" ratio="21:1" mdxType="ColorContrast" />
      <ColorContrast background="#fff" foreground="#fff" ratio="1:1" mdxType="ColorContrast" />
    </ImageGallery>
    <p>{`WCAG defines two levels of compliance for color contrast.`}</p>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#dfn-text"
      }}>{`Regular text`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#dfn-images-of-text"
      }}>{`images of text`}</a>{` must satisfy a `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#contrast-minimum"
      }}>{`minimum contrast ratio 4.5:1`}</a>{`. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#contrast-enhanced"
      }}>{`enhanced minimum requirement is 7:1`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`The first of the following two examples shows medium gray text on a light gray background, with a contrast ratio of 4.6:1. The second example shows a darker gray for the text, with the same light gray background, with a contrast ratio of 7.19:1.`}</em></p>
    </blockquote>
    <ImageGallery mdxType="ImageGallery">
      <ColorContrast background="#ccc" foreground="#565656" ratio="4.6:1" fontSize="1" mdxType="ColorContrast" />
      <ColorContrast background="#ccc" foreground="#393939" ratio="7.19:1" fontSize="1" mdxType="ColorContrast" />
    </ImageGallery>
    <h4 {...{
      "id": "large-text"
    }}>{`Large text`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#dfn-large-scale"
      }}>{`Large-scale text`}</a>{`, or text of a size that's at least 18 point or 14 point bold, and images of large-scale text must meet a contrast ratio of at least 3:1. The enhanced minimum requirement is 4.5:1.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Both of the following examples use a 24px font size. The first example shows medium gray text on a light gray background, with a contrast ratio of 3.08:1. The second example shows a darker gray for the text, with the same light gray background, with a contrast ratio of 4.6:1.`}</em></p>
    </blockquote>
    <ImageGallery mdxType="ImageGallery">
      <ColorContrast background="#ccc" foreground="#707070" ratio="3.08:1" fontSize="4" mdxType="ColorContrast" />
      <ColorContrast background="#ccc" foreground="#565656" ratio="4.6:1" fontSize="4" mdxType="ColorContrast" />
    </ImageGallery>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/color-contrast"
        }}>{`text elements have sufficient color contrast against the background`}</a>{`.`}</p>
    </Callout>
    <h3 {...{
      "id": "2-missing-alt-text-for-images"
    }}>{`#2: Missing alt text for images`}</h3>
    <p>{`The WebAIM Million report found missing alt text for images on 66.3% of tested home pages in February 2020.`}</p>
    <p>{`Missing alt text for images is the second most common culprit. Ensure that you provide the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute and a descriptive text value for each web image.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src="./feature-image" />
`}</code></pre>
    <p>{`This example image element above is missing an `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute. If you can't see the image and there's no alternative text, there's no means for a user to understand the intent of the image.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img
  src="./feature-image"
  alt="Short-coated brown puppy, background blurred."
/>
`}</code></pre>
    <p>{`With the alt attribute providing sufficient descriptive text, users can discern the content of the photo without having seen the image.`}</p>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/image-alt"
        }}>{`images have alternative text`}</a>{`.`}</p>
    </Callout>
    <h3 {...{
      "id": "3-empty-links"
    }}>{`#3: Empty links`}</h3>
    <p>{`The WebAIM Million report found empty links on 59.9% of tested home pages in February 2020.`}</p>
    <p>{`If a link contains no text, the function or purpose of the link will be imperceptible to some users. This happens when an anchor element has an `}<inlineCode parentName="p">{`href`}</inlineCode>{` attribute but contains no text, or a linked image has no alternative text.`}</p>
    <p>{`This example pulled from the Washington Post's homepage shows a common example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<a href="http://www.twitter.com/washingtonpost">
    <span class="fa fa-twitter"></span>
</a>
`}</code></pre>
    <p>{`That markup displays a social icon that links out to Twitter. However, it lacks link text to communicate the purpose of the link non-visually.`}</p>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/link-name"
        }}>{`links have discernible text`}</a>{`.`}</p>
    </Callout>
    <h3 {...{
      "id": "4-missing-form-input-labels"
    }}>{`#4: Missing form input labels`}</h3>
    <p>{`The WebAIM Million report found missing form input labels on 53.8% of tested home pages in February 2020.`}</p>
    <p>{`Missing form input labels can make it difficult or impossible for users to submit forms.`}</p>
    <h4 {...{
      "id": "example-with-form-input-labels-missing"
    }}>{`Example with form input labels missing`}</h4>
    <p>{`In this example, the text "Name" is near the intended input area. That relationship will be clear to most sighted users, but not so for others. If the proper markup is not present, screen readers must guess what text, if any, describes what field.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
    Name:
    <input type="text" name="name"/>
</div>
`}</code></pre>
    <h4 {...{
      "id": "fixed-example-with-form-input-labels"
    }}>{`Fixed example with form input labels`}</h4>
    <p>{`To explicitly associate labels with form fields, labels must be marked up with the HTML `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` element. The `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` element takes a `}<inlineCode parentName="p">{`for`}</inlineCode>{` attribute, whose value should match the `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute of the associated form element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div>
    <label for="name">Name:</label>
    <input
      id="name"
      type="text"
      name="name"
    />
</div>
`}</code></pre>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/label"
        }}>{`form `}<inlineCode parentName="a">{`<input>`}</inlineCode>{` elements have labels`}</a>{`.`}</p>
    </Callout>
    <h3 {...{
      "id": "5-empty-buttons"
    }}>{`#5: Empty buttons`}</h3>
    <p>{`The WebAIM Million report found empty buttons on on 28.7% of tested home pages in February 2020.`}</p>
    <p>{`Users of assistive technology can have trouble discerning the purpose of a button that has no accessible name. Buttons must have discernible text that clearly describes the button's purpose, function, or action. There are many ways to accomplish this. Check out the `}<a parentName="p" {...{
        "href": "https://dequeuniversity.com/rules/axe/3.3/button-name"
      }}>{`Deque `}<inlineCode parentName="a">{`button-name`}</inlineCode>{` rule page`}</a>{`.`}</p>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/button-name"
        }}><inlineCode parentName="a">{`<button>`}</inlineCode>{` elements have discernible text`}</a>{`.`}</p>
    </Callout>
    <h3 {...{
      "id": "6-missing-document-language"
    }}>{`#6: Missing document language`}</h3>
    <p>{`The WebAIM Million report found the document language was missing on on 28.0% of tested home pages in February 2020.`}</p>
    <p>{`Screen reader users select a default language. If the language of a page is not specified, the screen reader will default to the user's selected default language. For multi-lingual users, this can result in strange behavior.`}</p>
    <p>{`For example, suppose a user navigates the web in both Spanish and English, and their default language is set to Spanish. If a website doesn't define the language of the content, and the content is in English, the screen reader will read aloud English content with a default voice designed for Spanish content.`}</p>
    <p>{`Ensure that you identify a language in the opening `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` element, using the `}<inlineCode parentName="p">{`lang`}</inlineCode>{` attribute:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html lang="es">
  <!--document head and body-->
</html>
`}</code></pre>
    <Callout mdxType="Callout">
      <p><strong parentName="p">{`Solution:`}</strong>{` Ensure the `}<a parentName="p" {...{
          "href": "https://dequeuniversity.com/rules/axe/3.3/html-has-lang"
        }}><inlineCode parentName="a">{`<html>`}</inlineCode>{` element has a `}<inlineCode parentName="a">{`lang`}</inlineCode>{` attribute`}</a>{`.`}</p>
    </Callout>
    <h2 {...{
      "id": "read-on"
    }}>{`Read on`}</h2>
    <p>{`Pour another cup and `}<a parentName="p" {...{
        "href": "/start-testing/"
      }}>{`start testing for web accessibility`}</a>{`.`}</p>
    <PreviousNextNav previous={{
      link: "/intro/",
      title: "Intro"
    }} next={{
      link: "/start-testing/",
      title: "Start Testing"
    }} mdxType="PreviousNextNav" />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`The `}<a parentName="li" {...{
            "href": "https://webaim.org/projects/million/"
          }}>{`WebAIM million study`}</a>{` cites this figure.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`WebAIM: `}<a parentName="li" {...{
            "href": "https://webaim.org/articles/contrast/"
          }}>{`Article on contrast`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      