import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Banner, Tiles, SEO } from "..";
import CoffeeIcons from "../components/coffee-icons";
import { Box } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Homepage" mdxType="SEO" />
    <Banner mdxType="Banner">
      <p>{`A11y Coffee`}</p>
      <h1 {...{
        "id": "pick-your-serving-size-of-web-accessibility-information"
      }}>{`Pick your serving size of web accessibility information`}</h1>
    </Banner>
    <Box mdxType="Box">
      <Tiles columns={4} mdxType="Tiles">
        <ol>
          <li parentName="ol">
            <h2 parentName="li" {...{
              "id": "intro"
            }}><a parentName="h2" {...{
                "href": "/intro/"
              }}>{`Intro`}</a></h2>
            <p parentName="li">{`What is web accessibility and why is it important?`}</p>
          </li>
          <li parentName="ol">
            <h2 parentName="li" {...{
              "id": "quick-wins"
            }}><a parentName="h2" {...{
                "href": "/quick-wins/"
              }}>{`Quick Wins`}</a></h2>
            <p parentName="li">{`As a web developer, how can I make an impact quickly?`}</p>
          </li>
          <li parentName="ol">
            <h2 parentName="li" {...{
              "id": "start-testing"
            }}><a parentName="h2" {...{
                "href": "/start-testing/"
              }}>{`Start Testing`}</a></h2>
            <p parentName="li">{`How do I test for accessibility in the browser?`}</p>
          </li>
          <li parentName="ol">
            <h2 parentName="li" {...{
              "id": "dig-in"
            }}><a parentName="h2" {...{
                "href": "/dig-in/"
              }}>{`Dig in`}</a></h2>
            <p parentName="li">{`We've just scratched the surface. Dig in and learn more.`}</p>
          </li>
        </ol>
      </Tiles>
    </Box>
    <Banner mdxType="Banner">
      <h2 {...{
        "id": "a11y-testing-is-like-making-coffee"
      }}><a parentName="h2" {...{
          "href": "/a11y-testing-making-coffee/"
        }}>{`A11y Testing is Like Making Coffee`}</a></h2>
      <p>{`Let's dive into a metaphor!`}</p>
    </Banner>
    <Banner mdxType="Banner">
      <h2 {...{
        "id": "a11y-deploy-friday"
      }}><a parentName="h2" {...{
          "href": "/a11y-friday/"
        }}>{`A11y Deploy Friday`}</a></h2>
      <p>{`DO deploy on Friday. And make it an accessibility fix. `}<span role="img" aria-label="smirking cat emoji"><span parentName="p" {...{
            "role": "img",
            "aria-label": "cat face with wry smile"
          }}>{`😼`}</span></span></p>
    </Banner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      