import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO } from "..";
import { PreviousNextNav } from "..";
import audio from "../assets/a11y-coffee-intro.m4a";
export const _frontmatter = {
  "title": "Intro"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Introduction to Web Accessibility" description="An intro to what web accessibility is, why it's important, and the guiding principles behind accessibility standards." mdxType="SEO" />
    <h1 {...{
      "id": "introduction-to-web-accessibility"
    }}>{`Introduction to web accessibility`}</h1>
    <h2 {...{
      "id": "summary"
    }}>{`Summary`}</h2>
    <p>{`This page contains:`}</p>
    <ul>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "/intro/#what-is-web-accessibility"
        }}>{`introduction to web accessibility`}</a>{`,`}</li>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "/intro/#what-is-a11y"
        }}>{`explanation of the "a11y" numeronym`}</a>{`,`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/intro/#the-case-for-web-accessibility"
        }}>{`case for web accessibility`}</a>{`,`}</li>
      <li parentName="ul">{`And an introduction to `}<a parentName="li" {...{
          "href": "/intro/#the-web-content-accessibility-guidelines-wcag"
        }}>{`the web content accessibility guidelines`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "what-is-web-accessibility"
    }}>{`What is web accessibility?`}</h2>
    <p>{`Web accessibility means that people with disabilities can use and understand the web.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`The goal of web accessibility is that all people can perceive, understand, navigate, interact with, and contribute to the Web.`}</p>
    <h2 {...{
      "id": "what-is-a11y"
    }}>{`What is "a11y"?`}</h2>
    <p>{`"A11y" is a numeronym of the word “accessibility”.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` Numeronyms are abbreviations that use numbers to shorten/abbreviate words. "Accessibility" becomes "a11y" by taking the first and last letters, "a" and "y", and tallying the remaining letters in between. In this case, there are 11 letters between "a" and "y", so it becomes "a11y". Another similar example is "i18n", which is the numeronym for "internationalization".`}</p>
    <p>{`It's often joked that the "a11y" numeronym is not accessible. It's not immediately understood, without explanation. So why use it?`}</p>
    <ol>
      <li parentName="ol">{`It's shorter. Social media platforms like Twitter have character limits. "A11y" uses nine fewer characters than the full word "accessibility".`}</li>
      <li parentName="ol">{`It's discoverable. The "a11y" numeronym is most often used in the context of "web accessibility", specifically. Because the word "accessibility" is used in so many other contexts, searching with "a11y" rather than "accessibility" will yield more relevant search results on web accessibility.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><em parentName="p"><a parentName="em" {...{
            "href": "https://twitter.com/TatianaTMac/"
          }}>{`Tatiana Mac`}</a>{` explains this in a `}<a parentName="em" {...{
            "href": "https://twitter.com/TatianaTMac/status/1194370433426046978?s=20"
          }}>{`tweet thread`}</a>{` far more eloquently than I'm attempting to here -- check that out.`}<sup parentName="em" {...{
            "id": "fnref-5"
          }}><a parentName="sup" {...{
              "href": "#fn-5",
              "className": "footnote-ref"
            }}>{`5`}</a></sup></em></p>
    </blockquote>
    <h2 {...{
      "id": "the-case-for-web-accessibility"
    }}>{`The case for web accessibility`}</h2>
    <p>{`Web accessibility is a basic human right. It increases business potential, and it's increasingly required by law.`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <h3 {...{
      "id": "web-accessibility-is-a-basic-human-right"
    }}>{`Web accessibility is a basic human right`}</h3>
    <p>{`The Web is an important resource in many aspects of life. For example: education, employment, government, commerce, health care, recreation, and more. The Web must be accessible to provide equal access and opportunity to all people. The `}<a parentName="p" {...{
        "href": "https://www.un.org/development/desa/disabilities/convention-on-the-rights-of-persons-with-disabilities.html"
      }}>{`United Nations Convention on the Rights of Persons with Disabilities`}</a>{` (CRPD) is an international human rights treaty. The CRPD defines access to technology like the Web as a basic human right.`}</p>
    <p>{`Accessibility supports social inclusion for:`}</p>
    <ul>
      <li parentName="ul">{`people with disabilities`}</li>
      <li parentName="ul">{`older people`}</li>
      <li parentName="ul">{`people in rural areas`}</li>
      <li parentName="ul">{`people in developing countries`}</li>
    </ul>
    <h3 {...{
      "id": "web-accessibility-increases-business-potential"
    }}>{`Web accessibility increases business potential`}</h3>
    <p>{`Accessible websites provide an improved user experience for everyone. A user with a positive experience navigating your website will be more likely to take action. For example, signing up for a newsletter, buying a product, etc.`}</p>
    <p>{`Web accessibility widens your potential customer base. Why would you choose to alienate potential customers?`}</p>
    <h3 {...{
      "id": "web-accessibility-is-increasingly-required-by-law"
    }}>{`Web accessibility is increasingly required by law`}</h3>
    <p>{`Countries are increasingly legislating protections for equal access to the web. This is a quickly-changing legal landscape. Building with accessibility in mind minimizes current and future legal risk. High-profile example cases include `}<a parentName="p" {...{
        "href": "https://www.lflegal.com/2019/01/dominos-ninth-circuit/"
      }}>{`Domino's`}</a>{` and `}<a parentName="p" {...{
        "href": "https://webaim.org/blog/target-lawsuit-settled/"
      }}>{`Target`}</a>{`.`}</p>
    <h2 {...{
      "id": "the-web-content-accessibility-guidelines-wcag"
    }}>{`The Web Content Accessibility Guidelines (WCAG)`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`World Wide Web Consortium (W3C)`}</a>{` maintains the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{`. The goal of WCAG is to provide a single shared standard for web accessibility.`}</p>
    <h3 {...{
      "id": "the-four-guiding-principles-of-accessibility"
    }}>{`The Four Guiding Principles of Accessibility`}</h3>
    <p>{`The current version of WCAG is 2.1. WCAG version 1.0 focused heavily on techniques and checkpoints for achieving web accessibility. Version 2 focuses instead on four main guiding principles of accessibility. Those four principles are perceivable, operable, understandable, and robust (together known as "POUR")`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`The following section is almost verbatim from a `}<a parentName="em" {...{
            "href": "https://threadreaderapp.com/thread/1130825247320489987.html"
          }}>{`tweet thread by Carie Fisher`}</a><sup parentName="em" {...{
            "id": "fnref-6"
          }}><a parentName="sup" {...{
              "href": "#fn-6",
              "className": "footnote-ref"
            }}>{`6`}</a></sup>{` (with minimal tweaks). Thank you Carie for this breakdown!`}</em></p>
    </blockquote>
    <h4 {...{
      "id": "perceivable"
    }}>{`Perceivable`}</h4>
    <p>{`Users must be able to perceive the information being presented. It cannot be imperceptible to all their senses.`}</p>
    <ul>
      <li parentName="ul">{`Questions to consider`}<ul parentName="li">
          <li parentName="ul">{`Is there anything on our website or app that a person with a disability would not be able to perceive?`}</li>
          <li parentName="ul">{`Does this work with different types of assistive technology devices? Make sure to consider as wide a variety of disabilities as possible.`}</li>
        </ul></li>
      <li parentName="ul">{`Examples`}<ul parentName="li">
          <li parentName="ul">{`Adding text alternatives to non-decorative images.`}</li>
          <li parentName="ul">{`Adding captions and transcripts to videos.`}</li>
          <li parentName="ul">{`Making sure the color is not the only method used to convey meaning.`}</li>
        </ul></li>
    </ul>
    <h4 {...{
      "id": "operable"
    }}>{`Operable`}</h4>
    <p>{`Users must be able to operate the interface. The interface cannot require interaction that a user cannot perform.`}</p>
    <ul>
      <li parentName="ul">{`Questions to consider`}<ul parentName="li">
          <li parentName="ul">{`Can users control interactive elements of our website/app?`}</li>
          <li parentName="ul">{`Does our website have any keyboard traps? Does it work for mobile and touch devices?`}</li>
        </ul></li>
      <li parentName="ul">{`Examples`}<ul parentName="li">
          <li parentName="ul">{`Using keyboard-only navigation.`}</li>
          <li parentName="ul">{`Making sure slideshows and media players have all of the controls shown.`}</li>
          <li parentName="ul">{`Making sure users have enough time to fill out a form.`}</li>
        </ul></li>
    </ul>
    <h4 {...{
      "id": "understandable"
    }}>{`Understandable`}</h4>
    <p>{`Users must be able to understand the information as well as the operation of the user interface. The content or operation cannot be beyond their understanding.`}</p>
    <ul>
      <li parentName="ul">{`Questions to consider`}<ul parentName="li">
          <li parentName="ul">{`Is all of the content clearly written?`}</li>
          <li parentName="ul">{`Are all of the interactions easy to understand?`}</li>
          <li parentName="ul">{`Does the order of the page make sense?`}</li>
        </ul></li>
      <li parentName="ul">{`Examples`}<ul parentName="li">
          <li parentName="ul">{`Write content at a 9th-grade reading level – don’t use a scrabble word when a simpler word will do.`}</li>
          <li parentName="ul">{`Make sure your website is predictable.`}</li>
          <li parentName="ul">{`Make sure any error messages on your website are clear and easy to resolve.`}</li>
        </ul></li>
    </ul>
    <h4 {...{
      "id": "robust"
    }}>{`Robust`}</h4>
    <p>{`Users must be able to access content as technologies advance. As technologies and user agents evolve, the content should remain accessible.`}</p>
    <ul>
      <li parentName="ul">{`Questions to consider`}<ul parentName="li">
          <li parentName="ul">{`Does our website only support the newest browsers or operating systems?`}</li>
          <li parentName="ul">{`Is our website developed with best practices?`}</li>
          <li parentName="ul">{`Does this work in both landscape and portrait orientations?`}</li>
        </ul></li>
      <li parentName="ul">{`Examples`}<ul parentName="li">
          <li parentName="ul">{`There are no real examples of this. Make sure to test your website/app as it evolves. After initial testing, test again when new features or functionality are added. Consider a11y an on-going maintenance task.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "read-on"
    }}>{`Read on`}</h2>
    <p>{`Pour another cup and move on to `}<a parentName="p" {...{
        "href": "/quick-wins/"
      }}>{`quick wins`}</a>{`.`}</p>
    <PreviousNextNav previous={false} next={{
      link: "/quick-wins/",
      title: "Quick Wins"
    }} mdxType="PreviousNextNav" />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`W3C: `}<a parentName="li" {...{
            "href": "https://www.w3.org/WAI/fundamentals/accessibility-intro/#what"
          }}>{`What is Accessibility`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`A11Y Project: `}<a parentName="li" {...{
            "href": "https://a11yproject.com/about/"
          }}>{`What does the term A11Y mean?`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>{`Tatiana Mac: `}<a parentName="li" {...{
            "href": "https://twitter.com/TatianaTMac/status/1194370433426046978?s=20"
          }}>{`Tweet thread on a11y numeronym`}</a><a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`W3C: `}<a parentName="li" {...{
            "href": "https://www.w3.org/WAI/business-case/"
          }}>{`The Business Case for Digital Accessibility`}</a><a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`WebAIM: `}<a parentName="li" {...{
            "href": "https://webaim.org/articles/pour/#principles"
          }}>{`Focusing on the principles of accessibility`}</a><a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-6"
        }}>{`Carie Fisher: `}<a parentName="li" {...{
            "href": "https://threadreaderapp.com/thread/1130825247320489987.html"
          }}>{`Tweet thread on POUR`}</a><a parentName="li" {...{
            "href": "#fnref-6",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      