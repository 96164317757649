import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO, PreviousNextNav, EggheadVideo } from "../../";
export const _frontmatter = {
  "title": "Start Testing"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Start Testing for Web Accessibility" description="An introduction to a variety of accessibility testing tools." mdxType="SEO" />
    <h1 {...{
      "id": "start-testing-for-web-accessibility"
    }}>{`Start Testing for Web Accessibility`}</h1>
    <h2 {...{
      "id": "summary"
    }}>{`Summary`}</h2>
    <p>{`A light introduction to a variety of accessibility testing tools. Run some audits over a mug of coffee (or whatever)! You will learn `}<em parentName="p">{`a ton`}</em>{` by running audits and addressing the resulting warnings and errors.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`You may find it useful to run audits with each tool as you read, to experiment with them. A great page to run tests on is "`}<a parentName="em" {...{
            "href": "https://www.washington.edu/accesscomputing/AU/before.html"
          }}>{`Accessible University`}</a>{`", a fictional university homepage intentionally designed to show accessibility errors.`}</em></p>
    </blockquote>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/start-testing/#lighthouse"
        }}>{`Lighthouse`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/start-testing/#wave"
        }}>{`WAVE`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/start-testing/#axe"
        }}>{`Axe`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/start-testing/#accessibility-insights"
        }}>{`Accessibility Insights`}</a></li>
    </ul>
    <h2 {...{
      "id": "lighthouse"
    }}>{`Lighthouse`}</h2>
    <EggheadVideo link="https://app.egghead.io/lessons/aria-test-web-accessibility-using-lighthouse" title="Test Web Accessibility using Lighthouse" mdxType="EggheadVideo" />
    <p><a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/lighthouse/"
      }}>{`Google's Lighthouse tool`}</a>{` is an open-source, automated tool for measuring web pages.`}</p>
    <h3 {...{
      "id": "testing-with-lighthouse"
    }}>{`Testing with Lighthouse`}</h3>
    <p>{`You can run Lighthouse `}<a parentName="p" {...{
        "href": "https://web.dev/measure"
      }}>{`in any browser without installing anything`}</a>{`, in Chrome using the `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/lighthouse/#devtools"
      }}>{`Chrome dev tools`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/lighthouse/#cli"
      }}>{`from the command line`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`There are other ways to run Lighthouse, like incorporating it into your continuous integration workflow, but these options are the most straightforward to get started with. Check out `}<a parentName="em" {...{
            "href": "https://developers.google.com/web/tools/lighthouse/"
          }}>{`Google's landing page for the Lighthouse tool`}</a>{` for more.`}</em></p>
    </blockquote>
    <h3 {...{
      "id": "interpreting-lighthouse-results"
    }}>{`Interpreting Lighthouse results`}</h3>
    <p>{`Depending on how you run Lighthouse, there are several audit categories you may see. If you run from web.dev, you'll see results for performance, accessibility, best practices, and SEO. If you run it from dev tools or the command line, you have more flexibility, and can choose which audits to run.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`These scores are useful benchmarks, but can give a false impression. A score of 100 for accessibility means that automated testing found no errors. But we know that automated testing doesn't catch everything. Still though, it's a great place to start!`}</em></p>
    </blockquote>
    <p>{`The audit will also return a listing of flagged violations and warnings, sortable by "impact" ("high", "medium", "low"). You'll find the accessibility warnings under the "Accessibility" category.`}</p>
    <p>{`Each flagged item links to a resource where you can learn how to address it. Going one-by-one through these items is a great way to learn.`}</p>
    <h2 {...{
      "id": "wave"
    }}>{`WAVE`}</h2>
    <EggheadVideo link="https://app.egghead.io/lessons/aria-test-web-accessibility-using-the-wave-browser-extension" title="Test Web Accessibility using the WAVE Browser Extension" mdxType="EggheadVideo" />
    <p>{`The `}<a parentName="p" {...{
        "href": "https://wave.webaim.org/"
      }}>{`WAVE Web Accessibility Evaluation Tool`}</a>{` is developed and made available as a free community service by `}<a parentName="p" {...{
        "href": "http://webaim.org/"
      }}>{`WebAIM`}</a>{`. With the WAVE tool, you can test for accessibility directly in the context of the page.`}</p>
    <h3 {...{
      "id": "testing-with-wave"
    }}>{`Testing with WAVE`}</h3>
    <p>{`You have several options for running WAVE. The easiest options are to run it in the browser (no need to install anything), or to run it via a browser extension.`}</p>
    <ul>
      <li parentName="ul">{`Run an audit on a web page from the `}<a parentName="li" {...{
          "href": "https://wave.webaim.org/"
        }}>{`WAVE homepage`}</a>{`.`}</li>
      <li parentName="ul">{`Install a `}<a parentName="li" {...{
          "href": "https://wave.webaim.org/extension/"
        }}>{`WAVE browser extension`}</a>{` (Chrome and Firefox available).`}</li>
    </ul>
    <h3 {...{
      "id": "interpreting-wave-results"
    }}>{`Interpreting WAVE results`}</h3>
    <p>{`WAVE will overlay the page with icons and indicators that present in-context feedback about the accessibility of elements on the page. WAVE also presents a sidebar with a report section with tab views:`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Summary`}</strong>{`: A summary of errors, alerts, features, structural elements and HTML5 elements and ARIA attributes detected.`}</li>
      <li parentName="ul"><strong parentName="li">{`Detail`}</strong>{`: A listing of all the WAVE icons in your page. Clicking an item will change your focus from the sidebar directly to the element in question in the audited page.`}</li>
      <li parentName="ul"><strong parentName="li">{`Documentation`}</strong>{`: Explanation of the WAVE icons and how you can make your page more accessible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Outline`}</strong>{`: The heading structure of the page.`}</li>
    </ul>
    <h3 {...{
      "id": "wave-views"
    }}>{`WAVE "Views"`}</h3>
    <p>{`WAVE displays accessibility information in three views:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Styles`}</strong>{`: Presents the styled page with embedded accessibility icons and indicators.`}</li>
      <li parentName="ul"><strong parentName="li">{`No Styles`}</strong>{`: Presents the page with styles disabled with embedded accessibility icons and indicators. This view is great for reviewing the underlying reading and navigation order of the page.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contrast`}</strong>{`: Shows only the contrast issues found on your page.`}</li>
    </ul>
    <h2 {...{
      "id": "axe"
    }}>{`Axe`}</h2>
    <EggheadVideo link="https://app.egghead.io/lessons/aria-test-web-accessibility-using-the-axe-browser-extension" title="Test Web Accessibility using the Axe Browser Extension" mdxType="EggheadVideo" />
    <p><a parentName="p" {...{
        "href": "https://www.deque.com/axe/"
      }}>{`Axe`}</a>{` is an accessibility testing engine for websites and other HTML-based user interfaces.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` It's an open-source product of `}<a parentName="p" {...{
        "href": "https://www.deque.com/"
      }}>{`Deque`}</a>{`, a digital accessibility company.`}</p>
    <h3 {...{
      "id": "testing-with-axe"
    }}>{`Testing with Axe`}</h3>
    <p>{`There are a variety of ways to test with the Axe library. The easiest way to test a web page in the browser is with the Axe browser extension (available for `}<a parentName="p" {...{
        "href": "http://bitly.com/aXe-Chrome"
      }}>{`Chrome`}</a>{` and `}<a parentName="p" {...{
        "href": "http://bit.ly/axe-Firefox"
      }}>{`Firefox`}</a>{`).`}</p>
    <p>{`There are just a few steps to testing with the Axe extension:`}</p>
    <ol>
      <li parentName="ol">{`Download the axe extension for Chrome or the axe extension for Firefox.`}</li>
      <li parentName="ol">{`Go to the page you want to test.`}</li>
      <li parentName="ol">{`Open up your browser’s developer tools menu.`}<ul parentName="li">
          <li parentName="ul">{`In Chrome, right-click and select "Inspect".`}</li>
          <li parentName="ul">{`In Firefox, right-click and select "Inspect Element".`}</li>
          <li parentName="ul">{`In either browser, use the keyboard shortcut `}<inlineCode parentName="li">{`Ctrl + Shift + C`}</inlineCode>{` on Windows, or `}<inlineCode parentName="li">{`Cmd + Shift + C`}</inlineCode>{` on Mac.`}</li>
        </ul></li>
      <li parentName="ol">{`Select the `}<inlineCode parentName="li">{`axe`}</inlineCode>{` tab in the developer tools menu.`}</li>
      <li parentName="ol">{`Hit the big “Analyze” button.`}</li>
    </ol>
    <h3 {...{
      "id": "interpreting-axe-results"
    }}>{`Interpreting Axe results`}</h3>
    <p>{`After analysis is complete, the Axe pane shows a list of accessibility issues it identified, as well as how many times the issue was found to occur on the page. Interacting with an issue shows more detail, including:`}</p>
    <ul>
      <li parentName="ul">{`A description of the issue`}</li>
      <li parentName="ul">{`A link to additional info on how to evaluate or address the issue`}</li>
      <li parentName="ul">{`A snippet of the code that has caused the issue`}</li>
      <li parentName="ul">{`The issue's impact level`}</li>
    </ul>
    <p>{`You can select "Inspect node" to inspect (in the "Elements" tab of your dev tools) the code related the issue. You can turn on "Highlight", which will visually outline the UI generated by the code related to the issue.`}</p>
    <h2 {...{
      "id": "accessibility-insights"
    }}>{`Accessibility Insights`}</h2>
    <p><a parentName="p" {...{
        "href": "https://accessibilityinsights.io/"
      }}>{`Accessibility Insights`}</a>{` is a set of free testing tools from Microsoft. It uses axe-core under the hood. The suite of testing tools currently includes browser extensions for Chrome and Edge Insider, as well as an application for testing Windows applications. We'll continue to focus on browser testing here, and talk about `}<a parentName="p" {...{
        "href": "https://accessibilityinsights.io/docs/en/web/overview"
      }}>{`Accessibility Insights for Web`}</a>{` (the browser extensions).`}</p>
    <h3 {...{
      "id": "testing-with-accessibility-insights"
    }}>{`Testing with Accessibility Insights`}</h3>
    <p>{`Accessibility Insights for Web supports three primary workflows: FastPass, Assessment, and Ad hoc tools.`}</p>
    <h4 {...{
      "id": "accessibility-insights-fastpass"
    }}>{`Accessibility Insights "FastPass"`}</h4>
    <p>{`The "FastPass" workflow is a two-step process that helps quickly identify common, high-impact accessibility issues.`}</p>
    <ol>
      <li parentName="ol">{`Automated checks: It automatically scans for compliance with approximately 50 accessibility requirements.`}</li>
      <li parentName="ol">{`Tab stops: It provides clear instructions and a visual helper to assist in identifying critical accessibility issues related to keyboard access, such as missing tab stops, keyboard traps, and incorrect tab order.`}</li>
    </ol>
    <h4 {...{
      "id": "accessibility-insights-assessment"
    }}>{`Accessibility Insights "Assessment"`}</h4>
    <p>{`The "Assessment" workflow assists in verifying that a web app or web site is compliant with WCAG 2.0 Level AA, through a combination of automated and manual tests (many of which are guided).`}</p>
    <ol>
      <li parentName="ol">{`Automated checks: It automatically checks for compliance with approximately 50 accessibility requirements.`}</li>
      <li parentName="ol">{`Manual tests: It provides step-by-step instructions, examples, and how-to-fix guidance for approximately 20 tests, such as keyboard, focus, semantics, pointer / motion, and more. Many tests are "assisted", which means that the tool identifies the test instances or provides a visual helper.`}</li>
    </ol>
    <h4 {...{
      "id": "accessibility-insights-ad-hoc-tools"
    }}>{`Accessibility Insights "Ad hoc tools"`}</h4>
    <p>{`The "Ad hoc tools" workflow includes the two steps of the "Fast Pass" workflow -- automated checks and tab stops -- and adds three more:`}</p>
    <ol>
      <li parentName="ol">{`Color: The "Color" visualization shows the target page in grayscale, which assists in manually identifying instances where color is the only visible means of conveying meaning.`}</li>
      <li parentName="ol">{`Headings: The "Headings" visualization visually identifies the heading levels of programmatically-identified headings on a page. Because headings can be restyled, and the actual heading element level isn't identified visually, heading order can easily become disordered.`}</li>
      <li parentName="ol">{`Landmarks: The "Landmarks" visualization highlights landmarks on the page, empowering you to visually confirm the appropriate use of landmarks on the page.`}</li>
    </ol>
    <p>{`All of these tools are part of the "Assessment", but they can be really helpful tools to reach for outside of the context of a full assessment.`}</p>
    <p>{`Each of these ad hoc tools have supporting pages with more information and "dos and don'ts". You don't need to know what you're looking for -- the tool will teach you what to look for!`}</p>
    <h2 {...{
      "id": "read-on"
    }}>{`Read on`}</h2>
    <p>{`Read on and find out how you can `}<a parentName="p" {...{
        "href": "/dig-in/"
      }}>{`keep digging deeper`}</a>{`.`}</p>
    <PreviousNextNav previous={{
      link: "/quick-wins/",
      title: "Quick Wins"
    }} next={{
      link: "/dig-in/",
      title: "Dig In"
    }} mdxType="PreviousNextNav" />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://wave.webaim.org/help"
          }}>{`WAVE help page`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://github.com/dequelabs/axe-core"
          }}>{`axe-core README`}</a>{`.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      