import { Banner, Tiles, SEO } from "../../../../src";
import CoffeeIcons from "../../../../src/components/coffee-icons";
import { Box } from "theme-ui";
import * as React from 'react';
export default {
  Banner,
  Tiles,
  SEO,
  CoffeeIcons,
  Box,
  React
};