import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { SEO } from "..";
import audio from "../assets/a11y-coffee-about.m4a";
export const _frontmatter = {
  "title": "About"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="About A11y Coffee" description="About the A11y Coffee project and its purpose." mdxType="SEO" />
    <h1 {...{
      "id": "about"
    }}>{`About`}</h1>
    <p>{`A11y Coffee is primarily intended for web developers, but may be useful for anyone interested in learning more about web accessibility.`}</p>
    <p>{`I created it because I wanted a single, consolidated resource to send when someone asks "what is web accessibility and how do I get started?" The goal of this resource is to summarize everything I would want to communicate to that person, to answer that question.`}</p>
    <p>{`I hope you find it useful!`}</p>
    <a rel="me" href="https://front-end.social/@amberley">Amberley</a>
    <p>{`PS. Some very kind folks have asked for a way to support this project. If you feel so inclined, I created a page where you can `}<a parentName="p" {...{
        "href": "https://ko-fi.com/amberley"
      }}>{`buy me a coffee`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      